var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.form)?_c('div',{staticClass:"news-form"},[_c('CardWrapper',{attrs:{"solo-footer":true}},[_c('ValidationObserver',{ref:"form",attrs:{"tag":"div"}},[_c('VCardText',[_c('div',{staticClass:"form-grid"},[_c('ValidationProvider',{staticClass:"form-grid__cell_full",attrs:{"name":"name","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"name":"name","label":"Название","placeholder":"Название","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,false,1301786852)}),_c('ValidationProvider',{staticClass:"form-grid__cell_full",attrs:{"name":"url","rules":"url","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"name":"url","label":"Ссылка для кнопки","placeholder":"Ссылка для кнопки","tooltip":"Если поле ссылки заполнено, то у новости будет показана целевая кнопка. При не заполенном поле кнопки не будет.","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.url),callback:function ($$v) {_vm.$set(_vm.form, "url", $$v)},expression:"form.url"}})]}}],null,false,3888215783)}),_c('AttachmentsWrapper',{staticClass:"form-grid__cell_full",attrs:{"single":true,"value":_vm.form.desktopMediaId,"files":_vm.desktopCoverFile,"placeholderUploader":"Загрузить файл","accept":"image/*","title":"Картинка"},on:{"update:value":function($event){return _vm.$set(_vm.form, "desktopMediaId", $event)},"changeFiles":function($event){return _vm.changeFile($event, 'desktopFile')}}}),_c('AttachmentsWrapper',{staticClass:"form-grid__cell_full",attrs:{"single":true,"value":_vm.form.mobileMediaId,"files":_vm.mobileCoverFile,"placeholderUploader":"Загрузить файл","accept":"image/*","title":"Картинка (мобильные)"},on:{"update:value":function($event){return _vm.$set(_vm.form, "mobileMediaId", $event)},"changeFiles":function($event){return _vm.changeFile($event, 'mobileFile')}}})],1)]),_c('VDivider'),_c('VCardText',[_c('div',{staticClass:"form-grid"},[_c('div',{staticClass:"form-grid__cell_full"},[_c('div',{staticClass:"form-grid__header"},[_c('div',{staticClass:"text-body-3"},[_vm._v("Настройки просмотра")]),_c('div',{staticClass:"grey--text"},[_vm._v("При входе на сайт")])])]),_c('div',{staticClass:"form-grid__cell_2"},[_c('ValidationProvider',{attrs:{"name":"viewStartAt","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateInput',{attrs:{"name":"viewStartAt","label":"Дата начала","placeholder":"Дата начала","max":_vm.form.viewEndAt,"invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.viewStartAt),callback:function ($$v) {_vm.$set(_vm.form, "viewStartAt", $$v)},expression:"form.viewStartAt"}})]}}],null,false,3587433312)})],1),_c('div',{staticClass:"form-grid__cell_2"},[_c('ValidationProvider',{attrs:{"name":"viewEndAt","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateInput',{attrs:{"name":"viewEndAt","label":"Дата окончания","placeholder":"Дата окончания","min":_vm.form.viewStartAt,"invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.viewEndAt),callback:function ($$v) {_vm.$set(_vm.form, "viewEndAt", $$v)},expression:"form.viewEndAt"}})]}}],null,false,2749319025)})],1),_c('ValidationProvider',{staticClass:"form-grid__cell_2",attrs:{"name":"viewLimit","rules":{ required: true, min_value: 1, max_value: 9999999, integer: true },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"name":"viewLimit","type":"number","placeholder":"Частота показов","label":"Частота показов","outlined":"","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.viewLimit),callback:function ($$v) {_vm.$set(_vm.form, "viewLimit", _vm._n($$v))},expression:"form.viewLimit"}})]}}],null,false,3442821835)}),_c('ValidationProvider',{staticClass:"form-grid__cell_full",attrs:{"name":"courses","rules":"requiredSelect","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CourseMultipleSearch',{attrs:{"name":"courses","options":_vm.coursesOption,"invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.courseIds),callback:function ($$v) {_vm.$set(_vm.form, "courseIds", $$v)},expression:"form.courseIds"}})]}}],null,false,3864178095)})],1)])],1)],1),_c('CardWrapper',{staticClass:"mt-6",attrs:{"solo-footer":true}},[_c('VCard',[_c('TiptapEditor',{attrs:{"type":"MENTOR","placeholder":"Введите текст новости...","showPreviewSwitcher":false,"limit":10000,"showButtonList":_vm.buttonList},model:{value:(_vm.form.text),callback:function ($$v) {_vm.$set(_vm.form, "text", $$v)},expression:"form.text"}}),_c('div',{staticClass:"news-form__footer jcsb"},[(_vm.edit)?_c('Parameters',{attrs:{"title":"Действия","list":_vm.menu,"iconName":"$arrowUp"},on:{"select-option":_vm.handleMenuItemClick}}):_c('ButtonTextIcon',{attrs:{"color":"teal darken-4","icon":"$eye"},on:{"click":function($event){_vm.showPreviewModal = true}}},[_vm._v("Предпросмотр")]),_c('VBtn',{attrs:{"color":"secondary"},on:{"click":_vm.handleSubmit}},[_vm._v(_vm._s(_vm.edit ? 'Сохранить' : 'Создать'))])],1)],1)],1),_c('Confirmation',{ref:"confirm"}),_c('NewsModal',{attrs:{"visible":_vm.showPreviewModal,"news":_vm.previewNews,"blank":true},on:{"closeModal":function($event){_vm.showPreviewModal = false}}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }