

































































































































import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

// components
import AttachmentsWrapper from '@/components/_uikit/AttachmentsWrapper.vue'
import ButtonTextIcon from '@/components/_uikit/buttons/ButtonTextIcon.vue'
import Confirmation from '@/components/modals/Confirmation.vue'
import CourseMultipleSearch from '@/components/_uikit/search/CourseMultipleSearch.vue'
import DateInput from '@/components/_uikit/controls/DateInput.vue'
import NewsModal from '@/components/modals/NewsModal.vue'
import Parameters from '@/components/_uikit/Parameters.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import TiptapEditor from '@/components/_uikit/editor/TiptapEditor.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import ManagerNewsModule from '@/store/modules/manager/news'
import { CourseLabelResource, MediaResource, NewsStoreData } from '@/store/types'
import { NewsRequestResource } from '@/components/GlobalModals.vue'

@Component({
  components: {
    AttachmentsWrapper,
    ButtonTextIcon,
    Confirmation,
    CourseMultipleSearch,
    DateInput,
    NewsModal,
    Parameters,
    TextInput,
    TiptapEditor,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class NewsForm extends Mixins(NotifyMixin) {
  @Ref() confirm!: Confirmation

  @Prop({ required: true })
  private form!: NewsStoreData

  @Prop({ default: true })
  private edit!: boolean

  @Prop({ default: () => ([]) })
  private coursesList!: CourseLabelResource[]

  private get previewNews(): NewsRequestResource {
    return {
      id: -1,
      desktopMedia: this.desktopFile && this.desktopFile[0] || undefined,
      mobileMedia: this.mobileFile && this.mobileFile[0] || undefined,
      name: this.form.name,
      text: this.form.text,
      url: this.form.url || '',
    }
  }

  private showPreviewModal = false
  private desktopFile: MediaResource[] = []
  private mobileFile: MediaResource[] = []

  private get desktopCoverFile(): MediaResource[] {
    if (this.edit && ManagerNewsModule.currentNews && ManagerNewsModule.currentNews.desktopMedia) {
      return [ManagerNewsModule.currentNews.desktopMedia]
    }
    return []
  }

  private get mobileCoverFile(): MediaResource[] {
    if (this.edit && ManagerNewsModule.currentNews && ManagerNewsModule.currentNews.mobileMedia) {
      return [ManagerNewsModule.currentNews.mobileMedia]
    }
    return []
  }

  private get courses() {
    return ManagerNewsModule.courses
  }

  private get menu () {
    return [
      { icon: '$eye', iconColor: 'teal darken-4', name: 'Показать предпросмотр', value: 'view' },
      { icon: '$trash', iconColor: 'red', name: 'Удалить', value: 'delete' },
    ]
  }

  private coursesOption = this.coursesList.length ?
    [{ disabled: true, name: 'Все мастера', value: 0 }, ...this.coursesList.map(course => ({ disabled: false, name: course.title, value: course.id })) ] :
    [{ disabled: false, name: 'Все мастера', value: 0 }]

  private buttonList = ['bold', 'italic', 'underline', 'strike', 'subscript', 'superscript', 'color', 'heading', 'align', 'formatClear']

  private changeFile(files: MediaResource[], type: 'desktopFile' | 'mobileFile') {
    this[type] = files
  }

  private handleRemove() {
    this.confirm.open(
      'Удаление новости',
      'Вы уверены, что хотите удалить новость?',
      {
        buttonConfirmText: 'Удалить',
      },
    )
      .then(this.deleteNews)
      .catch(() => {return})
  }

  private handleMenuItemClick(action: 'view' | 'delete') {
    if (action === 'view') {
      this.showPreviewModal = true
    }
    if (action === 'delete') {
      this.handleRemove()
    }
  }

  private handleSubmit() {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          if (this.form.text.length > 10000) {
            this.notifyError('Текст превышает допустимое количество символов.')
            return
          }
          if (!this.form.text || this.form.text === '<p></p>') {
            this.notifyError('Текст новости обязателен для заполнения.')
            return
          }
          this.$emit('submit', this.form)
        } else {
          this.notifyError('Проверьте введенные данные.')
        }
      })
  }

  private mounted() {
    if (!this.courses.length) {
      this.fetchCourses()
    } else {
      this.coursesOption = [...this.coursesOption, ...this.courses.map(course => ({ disabled: false, name: course.name, value: +course.value }))]
    }
  }

  private fetchCourses() {
    ManagerNewsModule.fetchCourses()
      .then(response => {
        const options = response.map(option => ({ disabled: false, name: option.name, value: +option.value }))
        this.coursesOption = [...this.coursesOption, ...options]
      })
      .catch(this.notifyError)
  }

  private deleteNews() {
    this.$emit('remove')
  }

  @Watch('form.courseIds')
  private watchFormCourses() {
    this.coursesOption = this.coursesOption.map(option => {
      if (!this.form.courseIds.length) {
        return {
          ...option,
          disabled: false,
        }
      }
      if (!option.value) {
        return {
          ...option,
          disabled: !this.form.courseIds.includes(0),
        }
      }
      return {
        ...option,
        disabled: this.form.courseIds.includes(0),
      }
    })
  }
}
